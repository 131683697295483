var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.jobResultHeaders,"items":_vm.jobs,"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","show-expand":['PACKAGE_WGT_AS_SCZ', 'WILL_IT_PLAY', 'OPTIMIZE_MEDIA'].includes(_vm.jobTypeName),"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"header.data-table-expand",fn:function(){return [_c('v-icon',[_vm._v("mdi-information-outline")])]},proxy:true},{key:"item.job-status",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('job-status',{attrs:{"job":item},on:{"job-updated":function($event){return _vm.onJobUpdated($event, index)}}})]}},{key:"item.will-it-play",fn:function(ref){
var item = ref.item;
return [(_vm.jobService.validOutput(item, 'WILL_IT_PLAY'))?_c('will-it-play',{attrs:{"will-it-play":_vm.jobService.outputByType(item, 'WILL_IT_PLAY').metadata.will_it_play}}):_vm._e()]}},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [(_vm.jobService.validOutput(item, 'THUMBNAIL'))?_c('thumbnail',{staticClass:"py-1",attrs:{"thumbnail-url":_vm.jobService.outputByType(item, 'THUMBNAIL').download_url}}):_vm._e()]}},{key:"item.media-preview",fn:function(ref){
var item = ref.item;
return [(_vm.jobService.validOutput(item, 'MEDIA_PREVIEW'))?_c('media-preview',{attrs:{"item-filename":item.filename,"media-filename":_vm.jobService.outputByType(item, 'MEDIA_PREVIEW').filename,"media-url":_vm.jobService.outputByType(item, 'MEDIA_PREVIEW').download_url},scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [(_vm.jobService.validOutput(item, 'THUMBNAIL'))?_c('thumbnail',{attrs:{"thumbnail-url":_vm.jobService.outputByType(item, 'THUMBNAIL').download_url}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.jobService.validOutput(item, _vm.jobOutputDownloads[_vm.jobTypeName]))?_c('download-action',{attrs:{"download-url":_vm.jobService.outputByType(item, _vm.jobOutputDownloads[_vm.jobTypeName]).download_url}}):_vm._e(),(_vm.jobService.validOutput(item, 'WILL_IT_PLAY') && _vm.jobService.outputByType(item, 'WILL_IT_PLAY').metadata.will_it_play === false)?_c('optimise-action',_vm._b({},'optimise-action',item,false)):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expand-fix pa-4",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"text-center",attrs:{"outlined":""}},[(_vm.jobService.validOutput(item, 'WILL_IT_PLAY'))?_c('will-it-play-metadata',{attrs:{"metadata":_vm.jobService.outputByType(item, 'WILL_IT_PLAY').metadata}}):(_vm.jobService.validOutput(item, 'OPTIMIZED_MEDIA'))?_c('optimize-media-metadata',{attrs:{"metadata":_vm.jobService.outputByType(item, 'OPTIMIZED_MEDIA').metadata}}):(_vm.jobService.validOutput(item, 'PACKAGED_WIDGET'))?_c('packaged-widget-metadata',{attrs:{"metadata":_vm.jobService.outputByType(item, 'PACKAGED_WIDGET').metadata}}):_c('span',[_vm._v("No Results")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }