<template>
    <v-data-table
        :headers="jobResultHeaders"
        :items="jobs"
        disable-pagination
        disable-filtering
        disable-sort
        hide-default-footer
        :show-expand="['PACKAGE_WGT_AS_SCZ', 'WILL_IT_PLAY', 'OPTIMIZE_MEDIA'].includes(jobTypeName)"
        mobile-breakpoint="0"
    >
        <template v-slot:header.data-table-expand>
            <v-icon>mdi-information-outline</v-icon>
        </template>

        <template v-slot:item.job-status="{ item, index }">
            <job-status :job="item" @job-updated="onJobUpdated($event, index)" />
        </template>
        
        <template v-slot:item.will-it-play="{ item }">
            <will-it-play 
                v-if="jobService.validOutput(item, 'WILL_IT_PLAY')" 
                :will-it-play="jobService.outputByType(item, 'WILL_IT_PLAY').metadata.will_it_play" 
            />
        </template>

        <template v-slot:item.thumbnail="{ item }">
            <thumbnail
                v-if="jobService.validOutput(item, 'THUMBNAIL')" 
                class="py-1"
                :thumbnail-url="jobService.outputByType(item, 'THUMBNAIL').download_url"
            />
        </template>

        <template v-slot:item.media-preview="{ item }">
            <media-preview
                v-if="jobService.validOutput(item, 'MEDIA_PREVIEW')"
                :item-filename="item.filename"
                :media-filename="jobService.outputByType(item, 'MEDIA_PREVIEW').filename"
                :media-url="jobService.outputByType(item, 'MEDIA_PREVIEW').download_url"
            >
                <template v-slot:thumbnail>
                    <thumbnail
                        v-if="jobService.validOutput(item, 'THUMBNAIL')"
                        :thumbnail-url="jobService.outputByType(item, 'THUMBNAIL').download_url"
                    />
                </template>
            </media-preview>
        </template>

        <template v-slot:item.actions="{ item }">
            <download-action
                v-if="jobService.validOutput(item, jobOutputDownloads[jobTypeName])"
                :download-url="jobService.outputByType(item, jobOutputDownloads[jobTypeName]).download_url" 
            />
            <optimise-action
                v-if="jobService.validOutput(item, 'WILL_IT_PLAY') && jobService.outputByType(item, 'WILL_IT_PLAY').metadata.will_it_play === false"
                v-bind="item"
            />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand-fix pa-4">
                <v-card outlined class="text-center">
                    <will-it-play-metadata 
                        v-if="jobService.validOutput(item, 'WILL_IT_PLAY')" 
                        :metadata="jobService.outputByType(item, 'WILL_IT_PLAY').metadata" 
                    />
                    <optimize-media-metadata 
                        v-else-if="jobService.validOutput(item, 'OPTIMIZED_MEDIA')" 
                        :metadata="jobService.outputByType(item, 'OPTIMIZED_MEDIA').metadata" 
                    />
                    <packaged-widget-metadata 
                        v-else-if="jobService.validOutput(item, 'PACKAGED_WIDGET')" 
                        :metadata="jobService.outputByType(item, 'PACKAGED_WIDGET').metadata" 
                    />
                    <span v-else>No Results</span>
                </v-card>
            </td>
        </template>
    </v-data-table>
</template>

<script>
import jobService from "@/services/jobs";

export default {
    name: 'JobResultsTable',
    components: {
        JobStatus: () => import("@/components/table_cells/JobStatus"),
        Thumbnail: () => import("@/components/table_cells/Thumbnail"),
        MediaPreview: () => import("@/components/table_cells/MediaPreview"),
        WillItPlay: () => import("@/components/table_cells/WillItPlay"),
        WillItPlayMetadata: () => import("@/components/table_cells/WillItPlayMetadata"),
        OptimizeMediaMetadata: () => import("@/components/table_cells/OptimizeMediaMetadata"),
        PackagedWidgetMetadata: () => import("@/components/table_cells/PackagedWidgetMetadata"),
        DownloadAction: () => import("@/components/table_cells/DownloadAction"),
        OptimiseAction: () => import("@/components/table_cells/OptimiseAction")
    },
    props: {
        jobTypeName: {
            type: String,
            required: true
        },
        jobs: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        jobService,
        jobOutputDownloads: {
            'EXTRACT_WGT_MEDIA': 'MEDIA_PREVIEW',
            'PACKAGE_WGT_AS_SCZ': 'PACKAGED_WIDGET',
            'OPTIMIZE_MEDIA': 'OPTIMIZED_MEDIA'
        }
    }),
    computed: {
        jobResultHeaders () {
            const headers = [
                { 
                    text: 'Filename', 
                    value: 'filename', 
                    width: this.$vuetify.breakpoint.smAndDown ? this.$vuetify.breakpoint.xsOnly ? '200px' : '300px' : null
                },
                { 
                    text: 'Status', 
                    value: 'job-status', 
                    width: '140px' 
                },
                { 
                    text: 'Thumbnail', 
                    value: 'thumbnail', 
                    jobs: ['WILL_IT_PLAY', 'OPTIMIZE_MEDIA'], 
                    width: '132px' 
                },
                { 
                    text: 'Preview', 
                    value: 'media-preview', 
                    jobs: ['EXTRACT_WGT_MEDIA', 'PACKAGE_WGT_AS_SCZ'], 
                    width: '132px' 
                },
                { 
                    text: 'Compatible Video?', 
                    value: 'will-it-play', 
                    jobs: ['WILL_IT_PLAY'], 
                    width: this.$vuetify.breakpoint.mdAndDown ? '104px' : '150px' 
                },
                { 
                    text: 'Actions', 
                    value: 'actions', 
                    jobs: ['EXTRACT_WGT_MEDIA', 'PACKAGE_WGT_AS_SCZ', 'OPTIMIZE_MEDIA', 'WILL_IT_PLAY'], 
                    width: '132px' 
                },
                { 
                    value: 'data-table-expand', 
                    jobs: ['PACKAGE_WGT_AS_SCZ', 'WILL_IT_PLAY', 'OPTIMIZE_MEDIA'], 
                    width: '56px' 
                }
            ]
            return headers.filter(x => x.jobs ? x.jobs.includes(this.jobTypeName) : true)
        } 
    },
    methods: {
        onJobUpdated (value, index) {
            this.$emit('job-updated', { value, index })
        }
    }
}
</script>

<style scoped>
.expand-fix {
    /* box-shadow: none !important; */
    border-bottom: 1px solid#E0E0E0;
    background-color:#FAFAFA;
}
</style>